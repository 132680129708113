<script setup lang="ts">
import type { SliceInterface, VoixCheckboxFieldInterface } from '@voix/types'
import type { Swiper as SwiperType } from 'swiper'
import { animate, scroll } from 'motion'
import { Autoplay, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'

export interface Props {
  fields: {
    showFavoriteThings: VoixCheckboxFieldInterface
    showTopBorder: VoixCheckboxFieldInterface
    showBottomBorder: VoixCheckboxFieldInterface
    autoPlay: VoixCheckboxFieldInterface
    enableParallax: VoixCheckboxFieldInterface
  }
  slice: SliceInterface
}

const props = defineProps<Props>()

defineSlice({
  name: { label: 'Magazine Slider Container', group: 'Magazine', layouts: ['*'] },
  preview: 'SlicesGlueMagazineSliderMagazineSliderContainer.jpg',
  description: `Big 'ol magazine slider`,
  tags: ['Slider'],
  slots: [{ name: 'default' }],
  fields: {
    showFavoriteThings: { type: 'checkbox', label: 'Show Favorite Things?' },
    showTopBorder: { type: 'checkbox', label: 'Show Top Border?', defaults: { checked: true } },
    showBottomBorder: { type: 'checkbox', label: 'Show Bottom Border?', defaults: { checked: true } },
    autoPlay: { type: 'checkbox', label: 'Auto Play Slider?', default: false },
    enableParallax: { type: 'checkbox', label: 'Enable Parallax?', default: false },
  },
  templates: [{ label: 'Magazine Slider Container', fields: { showBottomBorder: { value: true }, showFavoriteThings: { value: true }, showTopBorder: { value: true } } }],
})

const swiper: Ref<SwiperType | null> = ref(null)

const autoplayOptions = computed(() => {
  if (props.fields.autoPlay.value) {
    return {
      delay: 100000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    }
  }

  return false
})

function onSwiper(swiperInstance: SwiperType) {
  swiper.value = swiperInstance
}

function next() {
  if (swiper.value)
    swiper.value.slideNext()
}

function prev() {
  if (swiper.value)
    swiper.value.slidePrev()
}

const container = ref<HTMLElement | null>(null)

onMounted(() => {
  if (props.fields.enableParallax.value) {
    const scrollOptions = {
      target: container.value,
      offset: ['start start', 'end start'],
    }

    scroll(animate(container.value, { height: ['100vh', '50vh'] }), scrollOptions)
  }
})
</script>

<template>
  <div ref="container" class="relative z-10 overflow-hidden w-full">
    <div id="magazine-slider-container">
      <div
        v-if="fields.showFavoriteThings.value"
        class="bg-white px-16 py-12 md:absolute z-30 top-24"
      >
        <div
          class="uppercase text-3xl font-sans font-light leading-snug antialiased tracking-wide"
        >
          A few of our
          <span
            class="normal-case italic text-4xl tracking-wider font-light text-red-500 font-serif"
          >favorite</span>
          things
        </div>
      </div>
      <div class="relative">
        <Swiper
          v-if="slice.elements"
          :slides-per-view="1"
          :modules="[Autoplay, Navigation]"
          :space-between="30"
          :loop="slice.elements.length > 1"
          :navigation="true"
          :autoplay="autoplayOptions"
          :centered-slides="true"
          class="border-white"
          :class="{
            'h-screen': fields.enableParallax.value,
            'border-t-10': fields.showTopBorder.value,
            'border-b-10': fields.showBottomBorder.value,
          }"
          @swiper="onSwiper"
        >
          <SwiperSlide v-for="(s, key) in slice.elements" :key="key" class="mb-24">
            <VoixSlice :slice="s as SliceInterface" :slice-index="key" />
          </SwiperSlide>
        </Swiper>

        <div
          v-if="slice.elements && slice.elements.length > 1"
          class="absolute bottom-0 mb-4 lg:inset-0 z-10 pointer-events-none flex lg:justify-between items-center space-x-2"
        >
          <button
            class="pointer-events-auto flex lg:absolute lg:z-50 left-0 ml-10 -mt-8 justify-center items-center"
            :class="{
              'top-0 bottom-0': !fields.enableParallax.value,
              '-right-[75%] xl:-right-[80%] 2xl:-right-[83%] bottom-12': fields.enableParallax.value,
            }"
            @click="prev"
          >
            <div
              class="slider-button text-orange-900 hover:text-white bg-orange-50"
            >
              <svg
                class="w-7 h-7"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </button>
          <button
            class="pointer-events-auto flex lg:absolute lg:z-50 right-0 mr-8 -mt-8 justify-center items-center"
            :class="{
              'top-0 bottom-0': !fields.enableParallax.value,
              'bottom-12': fields.enableParallax.value,
            }"
            @click="next"
          >
            <div
              class="slider-button text-orange-900 hover:text-white bg-orange-50"
            >
              <svg
                class="w-7 h-7 transform rotate-180"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.swiper-slide {
  width: auto;
  height: auto;
  display: flex;
}
</style>

<style>
.swiper-wrapper {
  @apply items-stretch;
}
</style>
